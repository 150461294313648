import superdocViewer from '@/components/Modals/SuperdocViewer.vue';
import { ModalProgrammatic as Modal } from 'buefy';

function openSuperdocViewerModal({
  linktitle,
  id,
  modules = {},
  fields = null,
  annotations = null,
  source = null,
  editorStateJson = null,
  headerCtas = null,
  onLoad = null,
  onError = null,
  attachments = [],
  events = {},
  parent = null,
}) {
  const ctas = headerCtas || [];
  const modal = Modal.open({
    component: superdocViewer,
    parent: parent || this,
    canCancel: true,
    customClass: 'superdoc-viewer-modal',
    width: 960, // default
    props: {
      name: linktitle,
      id,
      source,
      editorStateJson,
      modules,
      headerCtas: ctas,
      fields,
      annotations,
      attachments,
    },
    events: {
      error: (error) => {
        console.error('Error opening superdoc viewer', error);
        if (onError) onError(modal);
      },
      load: (file) => {
        if (onLoad) onLoad(modal, file);
      },
      ...events,
    },
  });
  return modal;
}

export default {
  install(Vue) {
    Vue.prototype.$openSuperdocViewerModal = openSuperdocViewerModal;
  },
};
