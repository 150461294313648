<script setup>
import '@harbour-enterprises/superdoc/style.css';
import { shallowRef, onMounted, onUnmounted, ref, watch } from 'vue';
import { Superdoc } from '@harbour-enterprises/superdoc';
import { useCkeditorStore } from '@/components/Ckeditor/stores/ckeditor-store';
import { downloadFile } from '@/utils/helpers/functions';
import throttle from '@/utils/throttle.js';
import { ToastProgrammatic as Toast } from 'buefy';

const skeletonComponents = [
  `
    <div class="line short"></div>
    <div class="line medium"></div>
    <div class="line long"></div>
    <div class="line medium"></div>
    `,
  `
    <div class="line medium"></div>
    <div class="line short"></div>
    <div class="line medium"></div>
    <div class="line long"></div>
    `,
  `
    <div class="line short"></div>
    <div class="line medium"></div>
    <div class="line long"></div>
    <div class="line short"></div>
    `,
  '', // empty div
];
const skeletonComponentSequence = '230131223101312101101'.split('');

const getSkeletonComponent = (index) => {
  return skeletonComponents[index];
};

const ckeditorStore = useCkeditorStore();

const emit = defineEmits([
  'ready',
  'load',
  'error',
  'editorCreate'
]);
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  source: {
    type: [File, String],
    required: false,
  },
  editorStateJson: {
    type: Object,
    required: false,
  },
  fields: {
    type: Array,
    required: false,
    default: () => [],
  },
  annotations: {
    type: Array,
    required: false,
    default: () => [],
  },
  conversations: {
    type: Array,
    required: false,
    default: () => [],
  },
  modules: {
    type: Object,
    default: () => {},
  },
  attachments: {
    type: Array,
    required: false,
    default: () => [],
  },
  headerCtas: {
    type: Array,
    default: () => [],
    validator: (ctas) => {
      const requiredKeys = ['id', 'label', 'type'];
      const types = ['primary', 'secondary', 'tertiary', 'confirm', 'alert'];

      if (!ctas.length) return false;

      const valid = ctas.every((cta) => {
        return requiredKeys.every((key) => cta[key]) && types.includes(cta.type);
      });
      if (!valid) {
        console.error(`Header CTAs must have {${requiredKeys.join(', ')}} keys and a "type" of {${types.join(', ')}}`);
        return false;
      }
      return true;
    },
  },
  overflowItems: {
    type: Array,
    required: false,
  },
});

const getCtaIconClass = (cta) => {
  const classes = ['icon', 'fa-' + cta.icon];
  if (cta.type === 'secondary') classes.push('fal');
  else classes.push('fas');
  return classes;
};

const superdocInstance = shallowRef(null);
const supereditorInstance = shallowRef(null);
const outerContent = ref(null);
const zoomOptions = ['50%', '75%', '90%', '100%', '125%', '150%', '200%'];
const zoomOptionsVisible = ref(false);
const overflowOptionsVisible = ref(false);
const zoomValue = ref('100%');
const zoomValueBuffer = ref('100%');
const pinchScale = ref(1);
const isZoomMode = ref(false);
const documentMode = 'viewing';
const isMobileView = ref(false);
const documentInitialWidth = ref(null);

const isLoading = ref(true);
const documentData = ref(null);

// for future download via converter
let documentDataString = '';

watch(isZoomMode, () => {
  getZoomedDocShift();
});

watch(outerContent, (val) => {
  if (val) {
    document.addEventListener('wheel', onPinchZoom, { passive: false });
  }
});

const onPinchZoom = (e) => {
  if (e.ctrlKey) {
    e.preventDefault();
    const s = Math.exp(-e.deltaY / 100);
    pinchScale.value *= s;
    const valueToApply = Math.floor(pinchScale.value * 100).toString();
    applyNewZoom(valueToApply);
  }
};

const getZoomedDocShift = () => {
  const transformSize = 50;
  const outerContentCtn = document.querySelector('.content-outer');
  const innerContentCtn = document.querySelector('.content-inner');
  const superdocElement = document.querySelector(superdocInstance.value.config.selector);
  const outerWidth = outerContentCtn.clientWidth;
  const innerWidth = superdocElement.clientWidth;

  if (outerWidth >= innerWidth) {
    resetCursorAndPos();
    return;
  }

  innerContentCtn.style.cursor = 'ew-resize';

  const leftShift = ((innerWidth - outerWidth) / 2) / outerWidth * 100;

  innerContentCtn.style.left = leftShift + transformSize + '%';
};

const resetCursorAndPos = () => {
  const contentCtn = document.querySelector('.content-inner');
  contentCtn.style.cursor = 'initial';
  contentCtn.style.left = '50%';
}

const downloadDocument = async () => {
  if (!documentData.value) return;

  // get type from file
  const fileType = documentData.value.type;

  if (fileType === "application/pdf") {
    downloadFile(documentData.value, props.name);
    return;
  }

  if (fileType === "text/html") {
    const toast = Toast.open({
      message: 'Downloading file...',
      position: 'is-top',
      type: 'is-info',
      indefinite: true,
    });

    const docxFileBlob = await ckeditorStore.exportToDocx(
      props.name,
      documentDataString
    );

    downloadFile(docxFileBlob, props.name);
    toast.close();
  }
};

const handleButtonClick = (button) => {
  if (button.disabled) return;

  const defaultClickHandlers = {
    download: downloadDocument
  }

  const clickHandler = button.onClick || defaultClickHandlers[button.id] || null;
  if (clickHandler) clickHandler();
};

const handleZoomSubmit = () => {
  resetCursorAndPos();
  applyNewZoom(zoomValueBuffer.value);
};

const handleZoomOptionClick = (option) => {
  setTimeout(() => applyNewZoom(option));
};

const handleZoomInputClick = () => {
  zoomOptionsVisible.value = !zoomOptionsVisible.value;
  zoomValueBuffer.value = '';
};

const applyNewZoom = (newZoom, innerWidth = '') => {
  if (newZoom.endsWith('%')) newZoom = newZoom.slice(0, -1);

  if (isNaN(newZoom) || newZoom <= 0) newZoom = 100;
  else if (newZoom < 20) newZoom = 20;
  else if (newZoom > 200) newZoom = 200;

  zoomOptionsVisible.value = false;
  const viewerInner = document.querySelector('.content-inner');
  superdocInstance.value.toolbar.setZoom(newZoom);

  if (!isMobileView.value || documentType.value === 'application/pdf') {
    if (newZoom > 100) {
      viewerInner.style.width = (816 * (newZoom/100)) + 'px';
      isZoomMode.value = true;
    } else {
      viewerInner.style.width = innerWidth || '8.5in';
      isZoomMode.value = false;
    }
  } else {
    viewerInner.style.width = '100%';
    isZoomMode.value = false;
  }

  zoomValueBuffer.value = newZoom + '%';
  zoomValue.value = zoomValueBuffer.value;
  pinchScale.value = newZoom / 100;

  getZoomedDocShift();
};

const getDocumentData = async () => {
  // check for file object, base64 string, or html string

  // check if file
  if (props.source instanceof File)
    return props.source;

  // string types
  if (typeof props.source === 'string') {
    documentDataString = props.source;
    if (props.source.startsWith('data:')) {
      // base64
      const fileType = props.source.split(';')[0].split(':')[1];
      const fileExtension = fileType.split('/')[1];
      if (!['pdf', 'docx'].includes(fileExtension)) throw new Error('Invalid file type');

      const fileName = `${props.name}.${fileExtension}`;
      const fileResp = await fetch(props.source);
      const fileBlob = await fileResp.blob();
      return new File([fileBlob], fileName, { type: fileType });
    }

    // html
    if (props.source.startsWith('<')) {
      return new File([props.source], `${props.name}.html`, { type: 'text/html' });
    }
  }

  console.error('Invalid source type');
  throw new Error('Invalid source type');
};

const documentType = ref('');

const initSuperdoc = async () => {
  try {
    documentData.value = await getDocumentData();
    documentType.value = documentData.value.type;

    isLoading.value = false;
    emit('load', documentData.value);
  } catch (e) {
    emit('error', e);
    Toast.open({
      message: 'Error opening document',
      type: 'is-danger',
      duration: 5000,
    });
    isLoading.value = false;
    return;
  }

  // for sign date, which uses a different itemid
  props.annotations?.forEach((annotation) => {
    let matchingField = props.fields.find((field) => field.itemid === annotation.itemid);
    if (!matchingField) {
      matchingField = props.fields.find((field) => field.itemid === annotation.itemoriginalid);
      annotation.itemid = matchingField ? annotation.itemoriginalid : annotation.itemid;
    }
  });

  const config = {
    selector: `#superdoc-${props.id}`,
    user: {},
    documents: [
      {
        id: props.id,
        data: documentData.value,
        state: props.editorStateJson,
        fields: props.fields,
        annotations: props.annotations,
        attachments: props.attachments,
        conversations: props.conversations
      },
    ],
    modules: props.modules,
    documentMode,
  };

  superdocInstance.value = new Superdoc(config);
  attachEventListeners(superdocInstance.value);

  emit('ready', {
    superdoc: superdocInstance.value,
  });
};

function attachEventListeners(superdoc) {
  superdoc.on('editorCreate', onEditorCreate);
  superdoc.on('document-ready', () => {
    getZoomToFit(true);
    applyNewZoom(zoomValueBuffer.value);
  });
}

function onEditorCreate({ editor }) {
  supereditorInstance.value = editor;
  // For the editor should we set `documentMode` separately?
  supereditorInstance.value.setDocumentMode(documentMode);

  emit('editorCreate', {
    editor,
  });
}

// For wide pdf documents and for pdf resize
const getZoomToFit = (onFirstLoad) => {
  if (documentType.value !== 'application/pdf') return;
  const contentSelector = isMobileView.value ? '.content-outer' : '.content-inner';
  const contentCtn = document.querySelector(contentSelector);

  if (onFirstLoad || !documentInitialWidth.value) {
    const superdocElement = document.querySelector(superdocInstance.value.config.selector);
    documentInitialWidth.value = superdocElement.clientWidth;
  }

  if (documentInitialWidth.value / contentCtn.clientWidth > 1) {
    zoomValueBuffer.value = Math.floor(contentCtn.clientWidth / documentInitialWidth.value * 100).toString();
  }
};

const onWindowResize = () => {
  isMobileView.value = window.matchMedia('(max-width: 768px)').matches;
  getZoomToFit();
  applyNewZoom(zoomValueBuffer.value, '100%');
  getZoomedDocShift();
};
const onWindowResizeThrottled = throttle(onWindowResize, 300);

onMounted(async () => {
  await initSuperdoc();

  onWindowResize();
  window.addEventListener('resize', onWindowResizeThrottled);
});

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResizeThrottled);
  document.removeEventListener('wheel', onPinchZoom);
});
</script>

<template>
  <div class="superdoc-viewer">
    <div class="header">
      <h1 v-if="isMobileView">{{ name }}</h1>
      <div class="header-parts">
        <div class="header-left">
          <h1 v-if="!isMobileView">{{ name }}</h1>
          <div v-if="!isMobileView" class="separator"></div>
          <div class="dropdown-options-outer" @click="handleZoomInputClick">
            <input class="zoom-input" v-model="zoomValueBuffer" :placeholder="zoomValue"
                   @keydown.enter="handleZoomSubmit" />
            <i :class="'fas fa-caret-' + (zoomOptionsVisible ? 'up' : 'down')"></i>
            <div class="dropdown-options-inner" v-if="zoomOptionsVisible">
              <div class="option" v-for="(option, index) in zoomOptions" :key="index"
                   @click="handleZoomOptionClick(option)">
                <span>{{ option }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
          <div @click="handleButtonClick(cta)"
               class="cta"
               :class="[cta.type, cta.disabled ? 'disabled' : null]"
               :title="cta.title || cta.label"
               v-for="cta in headerCtas" :key="cta.id">
            <i v-if="cta.icon" :class="getCtaIconClass(cta)"></i>
            <span>{{ cta.label }}</span>
          </div>
          <div class="overflow" v-if="overflowItems && overflowItems.length"
               @click="overflowOptionsVisible = !overflowOptionsVisible">
            <div class="dropdown-options-outer">
              <i class="fas fa-ellipsis-vertical"></i>
              <div class="dropdown-options-inner" :style="{ right: 0 }" v-if="overflowOptionsVisible">
                <div class="option" @click="handleButtonClick(option)" v-for="option in overflowItems" :key="option.id">
                  <i v-if="option.icon" :class="['icon', 'fas', 'fa-' + option.icon]"></i>
                  <span>{{ option.label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-frame">
      <div
        ref="outerContent"
        :class="{ 'content-outer': true, 'zoom-mode': isZoomMode }"
        @click="zoomOptionsVisible = false; overflowOptionsVisible = false;"
      >
        <!-- comments etc -->
        <div
          class="content-inner"
        >
          <div v-if="isLoading">
            <div class="loading">
              <div class="skeleton">
                <div v-for="(i, index) in skeletonComponentSequence" v-html="getSkeletonComponent(i)" :key="index"
                  :class="'line-ctn type-' + i"></div>
              </div>
              <!--  end skeleton -->
            </div>
          </div>
          <div :class="['superdoc-app-container', {'is-pdf': documentData && documentData.type === 'application/pdf'}]" :id="'superdoc-' + id"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.superdoc-viewer {
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
  min-width: 960px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 16px;
    border-bottom: 1px solid #e0e0e0;
    background-color: white;
    border-radius: 10px 10px 0 0;

    h1 {
      max-width: 500px;
      display: inline;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-parts {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .dropdown-options-outer {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 99;

      .zoom-input {
        width: 50px;
        border: solid 1px #c4c4c4;
        border-radius: 8%;
        margin-right: 5px;
        text-align: center;

        &:focus {
          outline: none;
        }
      }


    }

    .dropdown-options-inner {
      position: absolute;
      top: 20px;
      z-index: 3;
      background-color: white;
      min-width: 50px;
      box-shadow: 1px 0px 15px 1px #00000014;
      border-radius: 8px;
      font-size: .8em;
      overflow: hidden;
    }

    .option {
      cursor: pointer;
      padding: 5px;
      display: flex;

      &:hover {
        background-color: #f7f7f7;
      }

      i {
        font-size: 10px;
      }

      span {
        &::selection {
          background-color: initial;
        }
      }
    }

    .overflow {
      cursor: pointer;
    }

    .separator {
      height: 20px;
      width: 1px;
      background-color: #e0e0e0;
      margin: 0 10px;
    }

    &-left,
    &-right {
      display: flex;
      align-items: center;
    }

    &-right {
      gap: 16px;
    }

    &-left {
      h1 {
        font-size: 1em;
        font-weight: 400;
      }
    }

    .cta {
      display: flex;
      align-items: center;
      font-weight: 600;
      color: white;
      padding: 7px 12px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.1s ease-in-out;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
          background-color: inherit;
        }
      }

      .icon {
        font-size: 13px;
        position: relative;
        left: -2px;
        font-weight: 600;
        display: flex;
      }
    }

    /* cta styles */
    .primary {
      color: white;
      background-color: #1355ff;

      .icon {
        color: white;
      }

      &:hover {
        background-color: #0133b7;
      }
    }

    .secondary {
      color: #333333;
      background-color: white;
      border: solid 1px #dbdbdb;

      .icon {
        font-weight: 500;
        color: #333333;
      }

      &:hover {
        background-color: #dbdbdb;
      }
    }

    .tertiary {
      color: #1355ff;

      .icon {
        color: #1355ff;
      }

      background-color: #e2e9fb;

      &:hover {
        background-color: #bccfff;
      }
    }

    .confirm {
      color: white;
      background-color: #00853d;

      .icon {
        color: white;
      }

      &:hover {
        background-color: #005d2b;
      }
    }

    .alert {
      color: white;
      background-color: #cb0e47;

      .icon {
        color: white;
      }

      &:hover {
        background-color: #8e0a32;
      }
    }
  }

  .content-frame {
    height: 100vh;
    padding-bottom: 10em;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .content-outer {
    height: 100%;
    max-width: 960px;
    position: relative;
    overflow: scroll;
    border-radius: 0 0 10px 10px;
    background-color: #f5f5f5;

    &::-webkit-scrollbar {
      display: none;
    }

    .content-inner {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      min-height: 90vh;
      background-color: white;
      display: flex;
      justify-content: center;
      word-break: break-word;
      width: 8.5in;
      margin: 1em auto;
      border-radius: 8px;
      box-shadow: 1px 0 15px 1px #00000014;
      touch-action: none;

      :deep(.superdoc) {
        justify-content: center;
      }

      :deep(img) {
        max-width: 100%;
        height: auto;
      }

      :deep(.text-field) {
        white-space: nowrap;
        height: 100%;
        width: 100%;
        border-radius: 2px;
        margin: 0;
        display: flex;
        align-items: center;
        padding: 1px;
      }

      :deep(.field-container) {
        border-radius: 2px;
        background-color: #EFD0F0 !important;
        border: 2px solid #B015B3;
      }

      :deep(.field-container--no-style) {
        background: none !important;
        border-color: transparent;
      }

      :deep(.checkbox-container) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10%;
      }

      /* signature annotation size limit */
      :deep([data-itemfieldtype="SIGNATUREINPUT"]) {
        display: inline-block;
        max-width: 80px;
      }
      :deep([data-itemfieldtype="SIGNATUREINPUT"]) img {
        max-height: 40px;
      }

      /* table overflow fix */
      :deep(figure.table) {
        width: initial !important;
        background-color: initial;
      }

      :deep(figure.table table) {
        background-color: white;
      }

      :deep(figure.table table), :deep(figure.table td) {
        border: solid 1px black;
      }

      /* Ck has centered images with this class, this preserves centering. */
      /* Other centered images will have text-align: center as inline style.*/
      :deep(figure.image) {
        margin: 0 auto;
      }

      :deep(.layers) {
        position: relative;
      }

      .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;

        .skeleton {
          padding-top: 2em;

          .line-ctn {
            display: flex;
            margin: 10px 0;

            &.type-2 {
              padding-left: 30px;
            }

            :deep(.line) {
              height: 12px;
              background-color: grey;
              background: linear-gradient(to right, #d0d0d0, #fafafa, #eee);
              background-size: 400%;
              background-position-x: 100%;
              animation: shimmer 1s infinite linear;
              border-radius: 5%;
              margin: 0 5px;

              @keyframes shimmer {
                to {
                  background-position-x: 0%;
                }
              }

              &.short {
                width: 70px;
              }

              &.medium {
                width: 150px;
              }

              &.long {
                width: 300px;
              }
            }
          }
        }
      }
    }
  }
  /* list styles */
  :deep(ul), :deep(ol) {
    margin-left: 2.666em;
    margin-top: 0;
    margin-bottom: 0;
  }
  /* docx list styles */
  :deep(ol) {
    list-style-type: decimal;
  }

  :deep(ol ol) {
    list-style-type: lower-latin;
  }

  :deep(ol ol ol) {
    list-style-type: lower-roman;
  }

  :deep(ol ol ol ol) {
    list-style-type: upper-latin;
  }

  :deep(ol ol ol ol ol) {
    list-style-type: upper-roman;
  }
  /* list styles for pdf paragraph fields (html inputs) */
  /* paragraph fields on pdf preview */
  :deep(.paragraph-field ol) {
    list-style-type: auto;
  }
  /* paragraph fields on docx preview */
  :deep([data-defaultstyle="defaultContentWrapper"] ol) {
    list-style: auto;
  }
  :deep([data-defaultstyle="defaultContentWrapper"] ol ol) {
    list-style: auto;
  }
  :deep([data-defaultstyle="defaultContentWrapper"] ol ol ol) {
    list-style: auto;
  }
  :deep([data-defaultstyle="defaultContentWrapper"] ol ol ol ol) {
    list-style: auto;
  }
  :deep([data-defaultstyle="defaultContentWrapper"] ol ol ol ol ol) {
    list-style: auto;
  }
}
@media (max-width: 960px) {
  .superdoc-viewer {
    min-width: 100%;
    .content-outer .content-inner {
      width: 100%;
      margin: 0;
    }
    :deep(.super-editor__content), :deep(.super-editor-in-viewer__content) {
      width: 100%;
      min-width: unset;
    }
  }
  .superdoc-app-container:not(.is-pdf) {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .superdoc-viewer {
    :deep(.super-editor__content), :deep(.super-editor-in-viewer__content) {
      padding: 30px 20px;
    }
    .header h1 {
      max-width: calc(100vw - 30px);
    }
    .header-right {
      .cta {
        padding: 0;
        background-color: #fff;
        border: none;
        .icon {
          font-size: 18px;
          color: #333;
        }
        span {
          display: none;
        }
        &:hover {
          background-color: transparent;
          opacity: .65;
        }
      }
    }
  }
}
</style>


<style>
/* Global styles */

/**
It's assumed that html viewer will be used only for previewing CK content.

CK uses its own styles for lists that differ from the default browser ones.
https://ckeditor.com/docs/ckeditor5/latest/getting-started/legacy/advanced/content-styles.html#the-full-list-of-content-styles
*/
.super-editor-in-viewer__content ul {
  list-style-type: disc;
}

.super-editor-in-viewer__content ul ul {
  list-style-type: circle;
}

.super-editor-in-viewer__content ul ul ul {
  list-style-type: square;
}

.super-editor-in-viewer__content ul ul ul ul {
  list-style-type: square;
}
</style>
