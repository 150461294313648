<script>
export default {
  name: 'HrbrAddressAutocomplete',
  props: {
    selectEventInProgress: {
      type: Boolean,
      required: false,
    },
    isWithFullSuggestionText: {
      type: Boolean,
      required: false,
    },
    // Google maps Places types: https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
    suggestionsType: {
      type: String,
      required: true,
    },
    isAutocompleteEnabled: {
      type: Boolean,
      required: false,
    },
    isWithMaps: {
      type: Boolean,
      required: false,
    },
    apiKey: {
      type: String,
      required: true,
    },
    metadataStore: {
      type: Object,
      required: false,
    },
    valueStore: {
      type: [Object, Array],
      required: false,
    },
    geoInputs: {
      type: Object,
      required: false,
    },
    autofilledInputs: {
      type: Object,
      required: false,
    },
    fullAddress: {
      type: Object,
      required: false,
    },
    itemId: {
      type: [String, Number],
      required: false,
    },
    autocompleteGroupId: {
      type: String,
      required: false,
    },
    inputType: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    refKey: {
      type: Number,
      required: false,
    },
    geoInputRefs: {
      type: Object,
      required: false,
    },
    globalSuggestions: {
      type: Object,
      required: false,
    },
    allInputs: {
      type: Array,
      required: false,
    },
    context: {
      type: Object,
      required: false,
    },
    invalidValue: {
      type: Boolean,
      required: false,
    },
    validationMessage: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      // data
      lastDebouncedActionFiredTime: 0,
      MIN_INPUT_CHARS: 4,
      DEBOUNCE_TIMEOUT: 3000,
      suggestions: [],
      signedMapsUrlsMap: {},
      finalSuggestionType: null,
      selection: null,
      canStoreMetadata: false, // true if metadata props present
      // keyboard behavior
      ignoreKeys: [],
      commandKeys: [],
      lastKeyPressed: null,
      autocompleteService: null,
      placesService: null,
      noSuggestionsTypes: ['COUNTYTEXTINPUT', 'COUNTRYTEXTINPUT'],

      // use in place of other falsy group id fallback
      NO_GROUP_ID: 'NO_AUTOCOMPLETE_GROUP_ID',
    };
  },

  created() {
    // Validate: Google variable initialized
    if (typeof google === 'undefined' || !google.maps || !google.maps.places) {
      console.log('Autocomplete Geo Input: failed to init');
      return;
    }

    this.ignoreKeys = ['Shift', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Control', 'Alt', 'Option', 'Tab', 'Meta', 'CapsLock'];
    this.commandKeys = ['Meta', 'Control'];

    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));

    // Store itemid in geo input object for address autofill
    const finalInputTypes = {
      CITYTEXTINPUT: 'city',
      STATETEXTINPUT: 'state',
      ZIPCODETEXTINPUT: 'zipcode',
      STREETADDRESSTEXTINPUT: 'street',
      COUNTRYTEXTINPUT: 'country',
      COUNTYTEXTINPUT: 'county'
    };
    const finalInputType = finalInputTypes[this.inputType] || this.inputType;

    const geoInputTypes = ['city', 'state', 'zipcode', 'street', 'country', 'county'];
    if (geoInputTypes.includes(finalInputType)) {
      const inputIds = this.geoInputs[finalInputType] || [];
      this.geoInputs[finalInputType] = [...inputIds, this.itemId];
    }

    // init full address for group
    if ((this.suggestionsType === 'ADDRESS' || this.suggestionsType === 'STREETADDRESS') && this.autocompleteGroupId) {
      this.fullAddress[this.autocompleteGroupId] = '';
    }

    // Init metadata
    this.canStoreMetadata = !!(this.metadataStore && this.itemId);
    if (this.canStoreMetadata && !this.metadataStore.hasOwnProperty(this.itemId)) {
      this.metadataStore[this.itemId] = {};
    }

    // Set suggestion type
    if (this.suggestionsType === 'ADDRESS' || this.suggestionsType === 'STREETADDRESS') {
      this.finalSuggestionType = 'address';
    }
    if (this.suggestionsType === 'REGIONS') {
      this.finalSuggestionType = '(regions)';
    }
    if (this.suggestionsType === 'STATES') {
      this.finalSuggestionType = 'administrative_area_level_1';
    }
    if (this.suggestionsType === 'CITIES') {
      this.finalSuggestionType = '(cities)';
    }
    if (this.suggestionsType === 'ESTABLISHMENT') {
      this.finalSuggestionType = 'establishment';
    }
    if (this.suggestionsType === 'COUNTRIES') {
      this.finalSuggestionType = 'country';
    }
    if (this.suggestionsType === 'COUNTIES') {
      this.finalSuggestionType = 'administrative_area_level_2';
    }
  },

  mounted() {
    // init vue refs
    if (this.geoInputRefs) {
      this.geoInputRefs[this.refKey] = {
        suggestionsType: this.suggestionsType,
        refs: this.$refs[`autocompleteInput${this.refKey}`],
        autocompleteGroupId: this.autocompleteGroupId || this.NO_GROUP_ID,
      };
    }
  },

  computed: {
    // Display text for suggestions (no dupes)
    suggestionsValues() {
      let suggestions = this.globalSuggestions && this.globalSuggestions.suggestions.length ? this.filteredGlobalSuggestions : this.suggestions;
      const values = suggestions.map((s) => this.getSuggestionValue(s, this.isWithFullSuggestionText));

      const unique = new Set(values);
      return [...unique];
    },

    filteredGlobalSuggestions() {
      if (!this.value) return this.globalSuggestions?.suggestions;
      return this.globalSuggestions?.suggestions.filter(s => this.getSuggestionValue(s, this.isWithFullSuggestionText).toLowerCase().includes(this.value.toLowerCase()));
    },

    // Mapping from values to place IDs for suggestions (for getDetails calls)
    suggestionsValuesToIds() {
      const valuesToIds = {};
      this.suggestions.forEach((s) => { valuesToIds[this.getSuggestionValue(s, this.isWithFullSuggestionText)] = s.place_id; });
      return valuesToIds;
    },

    location() {
      if (this.context?.geo_city) {
        const [lat, lng] = this.context?.geo_city.split(',')
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      }
      return null;
    },

    noGoogleAutocomplete() {
      if (this.suggestionsType === 'COUNTRIES') return true;
      return this.suggestionsType === 'STATES' && this.globalSuggestions.suggestions.length > 0;
    }
  },
  methods: {
    // Get long or short suggestion text depending on prop
    getSuggestionValue(suggestion, withFullText) {
      if (withFullText) {
        return suggestion.description;
      }
      return suggestion.structured_formatting?.main_text || suggestion;
    },

    // display formatting for suggestions dropdown
    formatSuggestion(suggestion) {
      if (!suggestion) {
        return suggestion;
      }
      if (['STREETADDRESS'].includes(this.suggestionsType)) {
        return suggestion.split(',')[0];
      }
      return suggestion;
    },

    // get suggestion details
    getDetailsFromPlaceId(placeId, callback = () => null) {
      if (this.selectEventInProgress) {
        return;
      }
      if (!this.placesService || !placeId) {
        callback(null);
      }

      this.placesService.getDetails({ placeId, fields: ['address_components'] }, (place, status) => {
        console.log('places API getDetails - getDetailsFromPlaceId');
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const components = place.address_components;
          const zipcode = components.find((c) => c.types.includes('postal_code'))?.long_name;
          const country = components.find((c) => c.types.includes('country'))?.long_name;
          const city = components.find((c) => c.types.includes('locality'))?.long_name;
          const state = components.find((c) => c.types.includes('administrative_area_level_1'))?.long_name;
          callback({
            zipcode,
            country,
            city,
            state
          });
        }
      });
    },

    getDetailsBySuggestionId(suggestion) {
      const suggestionId = this.suggestionsValuesToIds[suggestion];
      return this.suggestions.find(s => s.place_id === suggestionId)?.details || '';
    },

    // get city or cities associated with address
    getAssociatedCities(components, callback = null) {
      // possible type will be found via includes()
      const possiblePlaceTypes = 'locality administrative_area_level_2 administrative_area_level_1';

      const cityComponent = components.find((c) => {
        for (const t of c.types) {
          // only use address components with possible types
          if (possiblePlaceTypes.includes(t)) {
            return c;
          }
        }
      });

      // if city component not found but callback present, call callback and return early
      if (!cityComponent && callback) {
        callback(this.globalSuggestions.suggestions);
        return;
      }

      // get suggestions based on possible city
      this.updateAutocompleteSuggestions(cityComponent.long_name, '(cities)', (suggestions) => {
        // only consider suggestions that resemble possible city (e.g. new york ~ new york city)
        const filteredSuggestions = suggestions.length ? suggestions.filter((s) => s.description.includes(cityComponent.long_name)) : [];

        if (filteredSuggestions.length) {
          // update global suggestions to be read as prop by other input
          const globalSuggestions = this.globalSuggestions.suggestions;
          globalSuggestions.push(filteredSuggestions[0]);
          this.$set(this.globalSuggestions, 'suggestions', globalSuggestions);

          if (callback) {
            callback(globalSuggestions);
          }
        }
      });
    },

    // Get Places objects
    updateAutocompleteSuggestions(value, suggestionType, callback = null) {
      if (!this.autocompleteService || !this.placesService) {
        return;
      }

      // Get autocomplete suggestions
      const request = {
        input: value,
        types: [suggestionType],
        ...(this.location && { locationBias: new google.maps.Circle({center: this.location, radius: 10000}) }),
      };
      this.autocompleteService.getPlacePredictions(request, async (suggestions, status) => {
        console.log('places API getPlacePredictions')
        // If input is empty
        if (status !== google.maps.places.PlacesServiceStatus.OK || !suggestions) {
          this.suggestions = [];
          return false;
        }

        // return places objects
        if (this.finalSuggestionType === '(regions)') { // states
          // get states only by excluding places with types returned by (cities) ((cities) is a subset of (regions))
          this.suggestions = suggestions.filter((s) => !s.types.includes('locality') && !s.types.includes('administrative_area_level_3')).slice(0, 3);
        } else {
          // all other types
          this.suggestions = suggestions.slice(0, 3);

          // append to suggestions zipcode if address
          if (this.finalSuggestionType === 'address') {
            this.suggestions.forEach((s) => {
              const placeId = this.suggestionsValuesToIds[s.description];
              if (!placeId) {
                return;
              }

              this.getDetailsFromPlaceId(placeId, ({ zipcode, country, city, state }) => {
                if (zipcode) s.description += (' ' + zipcode);

                s.details = `${city || state}, ${country}`;
              });
            });
          }
        }

        // sign urls for map images
        const signedMapsUrlsMap = await this.getSignedMapsUrlsMap();
        this.signedMapsUrlsMap = {...this.signedMapsUrlsMap, ...signedMapsUrlsMap};

        if (callback) {
          callback(this.suggestions);
        }
        return true;
      });
    },

    // handling of keyboard events
    keydownHandler(e) {
      // Close dropdown on enter (like escape and tab), but don't defocus
      if (e.key === 'Enter') {
        const ref = this.$refs[`autocompleteInput${this.refKey}`];
        if (Array.isArray(ref)) {
          ref.forEach((a) => { a.isActive = false; });
        } else {
          ref.isActive = false;
        }
        return;
      }

      // On typing, selected location map in input will be cleared, with the exception of following keys and key combinations:
      // Don't respond to these key presses and don't respond to keyboard command (e.g. copy, select all)
      if (this.ignoreKeys.includes(e.key) || this.commandKeys.includes(this.lastKeyPressed)) {
        this.lastKeyPressed = e.key;
        return;
      }

      // Clear old selection
      this.selection = null;

      // Reset old autofill flag
      if (this.autofilledInputs) {
        this.autofilledInputs[this.itemId] = false;
      }
      if (this.fullAddress && this.autocompleteGroupId) {
        this.$set(this.fullAddress, this.autocompleteGroupId, null);
      }
      if (this.globalSuggestions) {
        this.$set(this.globalSuggestions, 'suggestions', []);
      }

      // Clear old metadata
      if (this.canStoreMetadata) {
        this.metadataStore[this.itemId] = {};
      }
    },

    onInput(event) {
      if (this.selectEventInProgress) {
        return;
      }

      this.$emit('input', event);

      // No need to wait for predefined suggestions list
      if (this.globalSuggestions.suggestions.length) return;

      // Ignore empty input, short input and debounce
      const timeElapsed = Date.now() - this.lastDebouncedActionFiredTime;
      if (!this.value || this.value.length < this.MIN_INPUT_CHARS || timeElapsed < this.DEBOUNCE_TIMEOUT) {
        return;
      }

      console.log('autocomplete onInput', event)
      this.lastDebouncedActionFiredTime = Date.now();
      this.updateAutocompleteSuggestions(this.value, this.finalSuggestionType);
    },

    // on drodown suggestion click, store suggestion to be used for input map url
    selectHandler(option) {
      this.$emit('select-event', option)

      // If option is null or undefined, return early
      if (!option) {
        return;
      }

      // Store selected value
      this.selection = option;
      // store full address/street address
      if ((this.suggestionsType === 'ADDRESS' || this.suggestionsType === 'STREETADDRESS') && this.autocompleteGroupId) {
        if (this.fullAddress) {
          this.$set(this.fullAddress, this.autocompleteGroupId, option);
        }
      }
      const selectedOption = this.suggestionsType === 'STREETADDRESS' ? option.split(', ')[0] : option;

      const valueObj = this.valueStore ? this.valueStore[this.itemId] : null;

      if (this.noGoogleAutocomplete) {
        this.storeUserInputValue(valueObj || this.itemId, this.itemId, option);
        this.$emit('blur');
        return;
      }

      // Store value in object: nested or unnested
      if (valueObj) {
        if (typeof (valueObj) === 'object') {
          setTimeout(() => this.$set(this.valueStore[this.itemId], 'itemlinkvalue', selectedOption), 0);
        } else {
          setTimeout(() => this.$set(this.valueStore, this.itemId, selectedOption), 0);
        }
      }

      // Store metadata
      const metadataStore = this.canStoreMetadata ? this.metadataStore[this.itemId] : null;

      // Get and store latitude longitude of selection
      const placeId = this.suggestionsValuesToIds[option];
      if (placeId) {
        // Store place id
        if (metadataStore) {
          metadataStore.googleplaces_placeid = placeId;
        }

        // Get only geometry details for latitude/longitude
        this.placesService.getDetails({ placeId, fields: ['geometry', 'address_components'] }, (place, status) => {
          console.log('places API getDetails - selectHandler');
          if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.location) {
            this.sendFormattedLocationData(place.address_components);

            // Autofill other geo inputs
            const autoFillers = ['ADDRESS', 'STREETADDRESS'];
            if (autoFillers.includes(this.suggestionsType) && this.autofilledInputs) {
              const components = place.address_components;

              // find city
              this.getAssociatedCities(components, (cities) => {
                const geoComponents = {
                  county: components.find(c => c.types.includes('administrative_area_level_2')),
                  country: components.find(c => c.types.includes('country')),
                  state: components.find((c) => c.types.includes('administrative_area_level_1')),
                  zipcode: components.find((c) => c.types.includes('postal_code')),
                };

                const geoValues = {
                  city: !cities.length || cities.length > 1 ? null : this.getSuggestionValue(cities[0], false),
                  state: geoComponents.state ? geoComponents.state.long_name : null,
                  zipcode: geoComponents.zipcode ? geoComponents.zipcode.long_name : null,
                  street: this.suggestionsType === 'STREETADDRESS' ? this.selection : this.selection?.split(', ').shift(),
                  country: geoComponents.country ? geoComponents.country.long_name : null,
                  county: geoComponents.county ? geoComponents.county.short_name : null
                };

                Object.keys(this.geoInputs).forEach((key) => {
                  const itemId = this.geoInputs[key];

                  if (itemId) {
                    // legacy support: no group id's and only one of each geo input
                    // ------------------------------------------------------------
                    if (!this.autocompleteGroupId && itemId.length === 1) {
                      this.storeUserInputValue(valueObj, itemId[0], geoValues[key]);
                    } else if (this.autocompleteGroupId) {
                      // multiple geo input groups (if no group id, no autofill)
                      // -------------------------------------------------------
                      itemId.forEach((id) => {
                        // Store value in object: nested or unnested
                        const input = this.allInputs.find((i) => i.itemid === id || i.itemid === this.valueStore[id]?.itemid) || {};
                        // Store value for inputs with like id
                        if (input.autocompletegroupid === this.autocompleteGroupId) {
                          this.storeUserInputValue(valueObj, id, geoValues[key]);
                        }
                      });
                    }
                  }
                });
              });
            }

            // Store latitude/longitude
            if (metadataStore) {
              metadataStore.latitude = place.geometry.location.lat();
              metadataStore.longitude = place.geometry.location.lng();
            }

            this.$emit('autofilled');
          }
        });
      }
    },

    getCenterImageUrl(center = '') {
      let centerVal = typeof center === 'object' ? center?.itemlinkvalue : center;
      const centerTokens = centerVal?.split(' ') || [];
      // check if last token is zip code and remove
      const lastToken = centerTokens[centerTokens.length-1]
      if (!isNaN(Number(lastToken))) {
        centerTokens.pop();
      }
      centerVal = centerTokens.join(' ')

      return this.signedMapsUrlsMap[centerVal] || '';
    },

    // Get string for map image url
    getMapsUrl(center) {
      const url = this.getCenterImageUrl(center);
      return `url(${url})`;
    },

    async getSignedMapsUrlsMap() {
      const locations = this.suggestions.map((s) => s.description);
      const urls = locations.map((center) => {
        let url = '';
        if (this.suggestionsType === 'STREETADDRESS' && this.autocompleteGroupId && this.fullAddress[this.autocompleteGroupId]) {
          url = `https://maps.googleapis.com/maps/api/staticmap?center=${this.fullAddress[this.autocompleteGroupId]}&zoom=11&size=100x100&key=${this.apiKey}`;
        } else {
          url = `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=11&size=100x100&key=${this.apiKey}`;
        }
        return url;
      });

      const response = await axios.post('/agreelinkdata?agreelinkdata-getsignedmapsurls', {
        requesttype: 'agreelinkdata-getsignedmapsurls',
        urls
      });

      const {data} = response;
      const signedUrls = data?.urls || [];

      const locationsToUrls = {};
      locations.forEach((location,index) => locationsToUrls[location] = signedUrls[index]);

      return locationsToUrls;
    },

    // save user input for submission
    storeUserInputValue(valueObj, itemId, value) {
      // Store value in object: nested or unnested
      if (valueObj) {
        if (typeof (valueObj) === 'object') {
          this.$set(this.valueStore[itemId], 'itemlinkvalue', this.formatSuggestion(value));
        } else {
          this.$set(this.valueStore, itemId, this.formatSuggestion(value));
        }

        // set flag
        this.$set(this.autofilledInputs, itemId, true);
      }
    },

    sendFormattedLocationData(components) {
      const country = components.find(item => item.types.includes('country')).short_name;
      const city = components.find(item => item.types.includes('locality'))?.long_name;
      const state = components.find(item => item.types.includes('administrative_area_level_1'))?.short_name;
      const county = components.find(item => item.types.includes('administrative_area_level_2'))?.long_name;

      this.$emit('get-location-autocomplete-data', {
        country,
        city: city || county,
        province_state: state,
        county
      });
    },
    dropdownActivate(evt) {
      if (!evt) {
        this.$emit('blur');
      }
    }
  },
}
</script>
<template>
  <div
    class="vue-autocomplete-input"
    data-testid="vue-autocomplete-input"
  >
    <div class="wrapper">
      <b-field
        :class="{
          'autocomplete-input': true,
          'suggestion-selected': inputType === 'ADDRESSTEXTINPUT' && (selection || (autofilledInputs && autofilledInputs[itemId])),
          'invalid-input': invalidValue,
          'with-map': !!getCenterImageUrl(valueStore && valueStore[itemId])
        }"
        :type="invalidValue ? 'is-danger' : null"
        :message="validationMessage"
        v-bind="$attrs"
      >
        <!-- autocomplete functionality enabled -->
        <div v-if="isAutocompleteEnabled">
          <b-autocomplete
            :ref="'autocompleteInput'+refKey"
            v-bind="$attrs"
            :data="suggestionsValues"
            :value="value"
            :open-on-focus="noGoogleAutocomplete"
            @active="dropdownActivate"
            @input="onInput"
            @keydown.native="keydownHandler($event)"
            @select="option => selectHandler(option)"
            max-height="500px">
            <template slot-scope="props">
              <div v-if="!noGoogleAutocomplete && isWithMaps" class="suggestion-map mr-3" :style="{backgroundImage: getMapsUrl(props.option)}">
                <div class="map-bg"></div>
              </div>
              <div class="suggestion-text">
                <p>{{formatSuggestion(props.option)}}</p>
                <p
                  v-if="suggestionsType === 'STREETADDRESS'"
                  class="suggestion-details"
                >
                  {{getDetailsBySuggestionId(props.option)}}
                </p>
              </div>
            </template>
          </b-autocomplete>

          <!-- Map displayed in input after making selection -->
          <div v-if="!noGoogleAutocomplete && isWithMaps && (selection || (autofilledInputs && autofilledInputs[itemId]))" class="selection-map" :style="{backgroundImage: getMapsUrl(valueStore && valueStore[itemId])}"></div>
        </div>

        <!-- autocomplete functionality disabled -->
        <div v-else>
          <b-input
            :value="value"
            @input="$emit('input', $event)"
            v-bind="$attrs"></b-input>
        </div>
      </b-field>
    </div>
  </div>
</template>
<style>
.wrapper {
  position: relative;
  width: 100%;
}

.wrapper .autocomplete-input .selection-map {
  left: 0;
  top: 0;
  z-index: 4;
  position: absolute;
  padding: 0;
  height: 100%;
  width: 2.5em;
  border-radius: 4px 0 0 4px;
  background-size: 50px;
  background-repeat: no-repeat;
}

.autocomplete-input.with-map .input {
  padding-left: 45px;
}

.autocomplete-input .autocomplete-option img {
  width: 50px;
}

.autocomplete-input.suggestion-selected .input {
  padding-left: 3em;
}

/* Prevent opening on top */
.autocomplete-input .dropdown-menu.is-opened-top {
  bottom: auto;
}

.autocomplete-input .dropdown-content {
  padding: 0;
}

.autocomplete-input .dropdown-content .dropdown-item {
  border-bottom: solid 1px #ececec;
  display: flex;
  align-items: center;
  padding: 0.5em;
}

.autocomplete-input .dropdown-content .dropdown-item .suggestion-map, .autocomplete-input .dropdown-content .dropdown-item .suggestion-map .map-bg  {
  width: 65px;
  height: 65px;
}

.autocomplete-input .dropdown-content .dropdown-item .suggestion-map .map-bg  {
  background-size: 80px;
  background-repeat: no-repeat;
}

.autocomplete-input .dropdown-content .dropdown-item .suggestion-map {
  background-color: #d6d6d6;
}

.autocomplete-input .dropdown-content .dropdown-item img {
  width: 80px;
}

.autocomplete-input .dropdown-content .dropdown-item .suggestion-text {
  width: 100%;
}

.autocomplete-input .dropdown-content .dropdown-item p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 70%;
}

@media all and (min-width: 480px){
  .autocomplete-input .dropdown-content .dropdown-item p {
    max-width: 80%;
  }
}

.autocomplete-input .dropdown-content .dropdown-item:last-child {
  border: none;
}

.autocomplete-input .suggestion-details {
  color: #848484;
}
</style>
